import { useParams } from "react-router-dom";
import { useUserProfileWithVideosQuery } from "services/graphql";

export function useGetUserProfileWithVideos() {
  const perPage = 10;
  const { username } = useParams<{ username: string }>();
  const { data, loading, error, fetchMore } = useUserProfileWithVideosQuery({
    variables: {
      username,
      videoPagination: {
        first: perPage,
      },
    },
  });

  if (!data) {
    return {};
  }

  const {
    videoUploads: {
      edges = [],
      totalCount: uploadedVideosCount,
      pageInfo,
    } = {},
  } = data.userProfileGetByUsername;

  const fetchMoreUserVideos = () => {
    fetchMore({
      variables: {
        videoPagination: {
          first: perPage,
          after: pageInfo?.endCursor,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousUserProfile = data.userProfileGetByUsername;
        const previousEntry = previousUserProfile.videoUploads;
        const fetchMoreUserProfile = fetchMoreResult.userProfileGetByUsername;
        const newEdges = fetchMoreUserProfile.videoUploads.edges;

        return {
          userProfileGetByUsername: {
            ...previousUserProfile,
            videoUploads: {
              ...fetchMoreUserProfile.videoUploads,
              edges: [...previousEntry.edges, ...newEdges],
            },
          },
        };
      },
    });
  };

  return {
    profileData: data.userProfileGetByUsername,
    videoUploads: edges,
    videoUploadsPageInfo: pageInfo,
    fetchMoreUserVideos,
    uploadedVideosCount,
    isUserProfileLoading: loading,
    hasUserProfileError: error,
  };
}
